














































































































































import { Component, Vue } from "vue-property-decorator";
import Header from "@/components/header.vue"; // @ is an alias to /src
import SidebarFinanceReports from "@/components/sidebar-finance-reports.vue"; // @ is an alias to /src
import ReportService from "@/services/reports-service";

@Component({
  components: {
    Header,
    SidebarFinanceReports,
  },
})
export default class FinanceReports extends Vue {

  projectSaleChartOption: any = {}
  projectSaleChartOptionExpenses: any = {};
  projectPerChartOption: any = {}
  revenue_projectPerChartOption: any = {}
  monthSaleChartOption: any = {};
  monthSaleChartOptionExpenses: any = {};
  monthSaleChartOptionsalary: any = {};
  vatChartOption: any = {}
  total_sales = 0;
  total_vat = 0;
  highest_sales = 0;
  highest_sales_id = '';
  highest_sales_po: any = {};
  selected_report_date_from: any = '';
  selected_report_date_to: any = '';

  total_invoices = 0;
  mounted() {
    this.retrieve();
  }


  getMonthName(month: any) {
    const d = new Date();
    d.setMonth(month - 1);
    const monthName = d.toLocaleString("default", { month: "long" });
    return monthName;
  }

  retrieve() {
    ReportService.getstatictics()
      .then((response) => {
        let master_data = response.data
        this.total_invoices = master_data['total_invoices'].toLocaleString()
        let projectsales = master_data['project_wise_sum']
        let monthsales = master_data['month_wise_sum']
        let vatsales = master_data['vat_wise_sum']

        // monthsales.forEach((x:any) => {x.month = this.getMonthName(x.month)})
        // let month = monthsales.map((x:any)=> x.month)
        // let data_monthly = monthsales.map((x:any)=> x.sum)

        // vatsales.forEach((x:any) => {x.month = this.getMonthName(x.month)})
        // let month_vat = vatsales.map((x:any)=> x.month)
        // let data_monthly_vat = vatsales.map((x:any)=> x.sum)

        this.projectExpenses(projectsales)
        this.projectRevenue(projectsales)
        this.projectPerSale(projectsales)
        this.projectPerSaleper(projectsales)

        this.monthlySale([], [])
        this.revenuemonthlySale([], [])
        this.salarymonthlySale([], [])
        this.monthlyVat([], [])

      })
      .catch((e) => {
        console.log(e);
      });
  }

  monthlySale(data: any, category: any) {
    this.monthSaleChartOption = {

      chart: {
        type: 'column'
      },
      title: {
        text: ''
      },
      credits: {
        enabled: false
      },
      xAxis: {
        categories: ["March", "April", "May", "June"],
        crosshair: true
      },
      yAxis: {
        min: 0,
        title: {
          text: 'SAR'
        }
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0
        }
      },
      series: [{
        name: 'Expenses Per Month',
        // colorByPoint: true, 
        data: [37.8, 790.34, 100, 121.5]

      }]
    }
  }

  revenuemonthlySale(data: any, category: any) {
    this.monthSaleChartOptionExpenses = {

      chart: {
        type: 'column'
      },
      title: {
        text: ''
      },
      credits: {
        enabled: false
      },
      xAxis: {
        categories: ["March", "April", "May", "June"],
        crosshair: true
      },
      yAxis: {
        min: 0,
        title: {
          text: 'SAR'
        }
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0
        }
      },
      series: [{
        name: 'Revenue Per Month',
        // colorByPoint: true, 
        data: [37.8, 790.34, 100, 121.5]

      }]
    }
  }
  salarymonthlySale(data: any, category: any) {
    this.monthSaleChartOptionsalary = {

      chart: {
        type: 'column'
      },
      title: {
        text: ''
      },
      credits: {
        enabled: false
      },
      xAxis: {
        categories: ["March", "April", "May", "June"],
        crosshair: true
      },
      yAxis: {
        min: 0,
        title: {
          text: 'SAR'
        }
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0
        }
      },
      series: [{
        name: 'Revenue Per Month',
        // colorByPoint: true, 
        data: [37.8, 790.34, 100, 121.5]

      }]
    }
  }


  monthlyVat(data: any, category: any) {
    this.vatChartOption = {

      chart: {
        type: 'column'
      },
      title: {
        text: ''
      },

      credits: {
        enabled: false
      },
      xAxis: {
        categories: ["March", "April", "May", "June"],
        crosshair: true
      },
      yAxis: {
        min: 0,
        title: {
          text: 'SAR'
        }
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0
        }
      },
      series: [{
        name: 'Monthly Paid VAT',
        colorByPoint: true,
        data: [37.8, 790.34, 100, 121.5]
      }]
    }
  }

  projectExpenses(data: any) {
    this.projectSaleChartOptionExpenses = {
      chart: {
        type: 'column'
      },
      title: {
        text: ' Project Wise Employees'
      },

      credits: {
        enabled: false
      },
      xAxis: {
        type: 'category'
      },
      yAxis: {
        title: {
          text: 'SAR'
        }
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            format: '{point.y:.0f}'
          }
        }
      },

      tooltip: {
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.0f}%</b> of total<br/>'
      },

      series: [
        {
          name: 'Sales',
          colorByPoint: true,
          data: [
            { name: "Head Office", y: 10 },
            { name: "Base Screw", y: 14 },
            { name: "Aramco", y: 21 },
            { name: "Aramco-Riyadh", y: 61 },
            { name: "Aramco-Jeddah", y: 61 },
            { name: "Yambu", y: 76 },
            { name: "Tanajeeb", y: 71 }
          ]
        }
      ]
    }
  }

  projectRevenue(data: any) {
    this.projectSaleChartOption = {
      chart: {
        type: 'column'
      },
      title: {
        text: 'Project Wise'
      },

      credits: {
        enabled: false
      },
      xAxis: {
        type: 'category'
      },
      yAxis: {
        title: {
          text: 'SAR'
        }
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            format: '{point.y:.1f}'
          }
        }
      },

      tooltip: {
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/>'
      },

      series: [
        {
          name: 'Sales',
          colorByPoint: true,
          data: [{ name: "Aramco", y: 2131 }, { name: "Aramco-Riyadh", y: 61321 }, { name: "Aramco-Jeddah", y: 7651 }]
        }
      ]
    }
  }

  projectPerSale(data: any) {
    this.projectPerChartOption = {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie'
      },
      title: {
        text: 'Saudi VS Non Saudi In %'
      },
      credits: {
        enabled: false
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
      },
      accessibility: {
        point: {
          valueSuffix: '%'
        }
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '<b>{point.name}</b>: {point.percentage:.1f} %'
          }
        }
      },
      series: [{
        name: 'Sales',
        colorByPoint: true,
        data: [{
          name: "Saudi", y: 200
        },
        { name: "Non-Saudi", y: 150 }
        ]
      }]
    }
  }

  projectPerSaleper(data: any) {
    this.revenue_projectPerChartOption = {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie'
      },
      title: {
        text: 'Attendance In %'
      },
      credits: {
        enabled: false
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
      },
      accessibility: {
        point: {
          valueSuffix: '%'
        }
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '<b>{point.name}</b>: {point.percentage:.1f} %'
          }
        }
      },
      series: [{
        name: 'Sales',
        colorByPoint: true,
        data: [
          { name: "Present", y: 340 },
          { name: "Not Present", y: 10 }
        ]
      }]
    }
  }

  clear() {
    this.selected_report_date_from = '';
    this.selected_report_date_to = '';
  }
  print() {
    let route: any = null;
    route = this.$router.resolve({ path: this.highest_sales_id });
    window.open(route.href);
  }
}
